import {useEffect, useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";

export default function OrderOnlineItem({order}) {

    const [item, setItem] = useState(order)
    const [loading, setLoading] = useState(false)
    const authStore = useAuthStore()

    useEffect(() => {
        let timer = setInterval(async () => {
            if (!loading) {
                getStatus();
            }
        }, 15000);

        return () => {
            clearInterval(timer);
        };
    }, [loading]);

    const getStatus = async () => {
        setLoading(true);
        try {
            if (item.is_pending === 'pending') {
                const response = await baseApi.get(`/invoices/${order.id}`);
                if (response.data?.order) {
                    setItem(response.data?.order);
                    if (response.data?.order.is_pending !== 'pending') {
                        console.log('Item',item)

                        if (response.data?.user) {
                            authStore.setUser(response.data?.user)
                            localStorage.setItem('user', JSON.stringify(response.data?.user))
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    return (
        <tr>
            <td>{order.id}</td>
            <td>{order.app}</td>
            <td className={'text-blue'}>{order.price.toFixed(authStore.icon==='syr'?0:2)} {authStore.icon==='syr'?'ل.س':'$'}</td>

            <td>{order?.phone} <i className="fa fa-copy fs-6 text-primary" onClick={() => authStore.copyText(item.phone)}/></td>
            <td>{item?.code} {item.code?.length>0?<i className="fa fa-copy fs-6 text-primary" onClick={() =>authStore.copyText(item.code)}/>:null}</td>
            <td>{item?.status}</td>
            <td>{item?.created_at}</td>
        </tr>
    )
}