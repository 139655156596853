import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import CategorySectionComponent from "../components/CategorySection/CategorySectionComponent";

export default function HomePage(){
    return (
        <>

            <SliderComponent/>
            <MarqueeComponent/>
            <CategorySectionComponent/>
        </>
    )
}