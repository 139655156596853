import {Link} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function ProductItem({item}){
const authStore=useAuthStore()
    return (
        <div className={'col-md-6 col-12 my-2'}>
            <Link to={`/products/${item.id}`} className={`btn btn-sm  py-1 px-3 w-100`}>
           <div className={'d-flex flex-row product-item  justify-content-between align-items-center' }>
              <div className={'container-img w-25'}>
                  {/*<div className="background" style={{backgroundImage:`url(${item.img})`}}></div>*/}
                  <img className={'product-img'} loading={'lazy'} src={item.img} alt={item.name}/>
              </div>
               <p className={'text-dark text-right h5'}>{item.name}</p>
               <span className={'rounded-ellipse text-white bg-green px-2 py-1 fs-8'}> {authStore.formatCurrency(item.price)} {authStore.icon==='syr'?'ل.س':''} </span>
              {/* <div className={'d-flex flex-column justify-content-around align-items-start'} style={{gap:'30px'}}>
                   <span className={'text-orange'}> {authStore.formatCurrency(item.price)} {authStore.icon==='syr'?'ل.س':''} </span>

               </div>*/}
           </div>
            </Link>
        </div>
    )
}