import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {useParams} from "react-router-dom";
import LoadingComponent from "../Loading/LoadingComponent";
import OnlineItemComponent from "./OnlineItemComponent";

export default function AppsComponent() {
    const [loading, setLoading] = useState(false)
    const [apps, setApps] = useState([])
    const department_id = useParams().id

    const getApps = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/online/${department_id}`)
            console.log(response.data)
            if (response.data?.apps?.length > 0) {
                setApps(response.data?.apps)

            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }
    useEffect(() => {
        getApps()
    }, [])

    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    }
    if (!loading && apps?.length === 0) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    <p className={'text-white bg-primary rounded py-2 fs-5 box-shadow'}>
                        لا يوجد أقسام متوفرة
                    </p>
                </div>
            </div>
        </div>
    }
    if (!loading && apps?.length > 0) {
        return <div className={'container'}>
            <div className="row justify-content-center">


                {apps.map((el) => {
                    return <OnlineItemComponent item={el} key={el.id}/>

                })}

            </div>
        </div>
    }

}