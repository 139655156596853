import Layout from "./components/Layout/Layout";

import {Route, Routes} from 'react-router-dom'
import HomePage from "./pages/HomePage";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DepartmentPage from "./pages/DepartmentPage";
import ProductsPage from "./pages/ProductsPage";
import ProductPage from "./pages/ProductPage";
import LoginPage from "./pages/LoginPage";
import {useAuthStore} from "./store/user-store";
import RegisterPage from "./pages/RegisterPage";
import {useEffect, useState} from "react";
import {baseApi} from "./api/axios";
import OrderPage from "./pages/OrderPage";
import BalancePage from "./pages/BalancePage";
import ProfilePage from "./pages/ProfilePage";
import OnlinePage from "./pages/OnlinePage";
import AppOnlinePage from "./pages/AppOnlinePage";
import OrderOnlinePage from "./pages/OrderOnlinePage";
import BanksPage from "./pages/BanksPage";
import ChargesPage from "./pages/ChargesPage";

export default function App() {
    const authStore = useAuthStore()
    const [loading, setLoading] = useState(false)
    const getSettings = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/settings');
            authStore.setSettings(response.data?.settings)
            if (response.data?.user?.id) {
                localStorage.setItem('user', JSON.stringify(response.data?.user))
                authStore.setUser(response.data?.user)

            }
        } catch (e) {
            toast.error('خطأ في الدخول للموقع يرجى تحديث الصفحة')
        }
        setLoading(false)
    }
    useEffect(() => {
        getSettings()
    }, [])

    return (
        <main data-theme={'dark'} className={`${authStore.gradient?'bg-gradient-main':'bg-color'}`}>
            <Layout>
                <ToastContainer/>

                <main>
                    <Routes>
                        <Route index element={<HomePage/>}/>
                        <Route path={'/categories/:id'} element={<DepartmentPage/>}/>
                        <Route path={'/departments/:id'} element={<ProductsPage/>}/>
                        <Route path={'/products/:id'} element={<ProductPage/>}/>
                        {authStore.user == null ? <Route path={'/login'} element={<LoginPage/>}/> : null}
                        {authStore.user == null ? <Route path={'/register'} element={<RegisterPage/>}/> : null}
                        {authStore.user?.name ? <Route path={'/orders'} element={<OrderPage/>}/> : null}
                        {authStore.user?.name ? <Route path={'/payments'} element={<BanksPage/>}/> : null}
                        {authStore.user?.name ? <Route path={'/charges'} element={<ChargesPage/>}/> : null}
                        {authStore.user?.name ? <Route path={'/balances'} element={<BalancePage/>}/> : null}
                        {authStore.user?.name ? <Route path={'/profile'} element={<ProfilePage/>}/> : null}
                        {authStore.online === true ? <Route path={'/online'} element={<OnlinePage/>}/> : null}
                        {authStore.online === true ? <Route path={'/online/:id'} element={<AppOnlinePage/>}/> : null}
                        {authStore.online === true ? <Route path={'/invoices'} element={<OrderOnlinePage/>}/> : null}
                        <Route path={'*'} element={<span className={'text-white fs-1'}>الصفحة غير موجودة</span>}/>
                    </Routes>
                </main>

            </Layout>
        </main>
    );
}


