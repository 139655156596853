import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {Link, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function PayFreeProduct({product}) {
    const redirect = useNavigate()
    const authStore = useAuthStore()
    const [qty, setQty] = useState(product.min_qty)
    const [player_id, setPlayerId] = useState('')
    const [player_name, setPlayerName] = useState('')
    const [note, setNote] = useState('')
    const [totalPrice, setTotalPrice] = useState(product.min_qty * product.unit_price)
    const [errors, setErrors] = useState({qty: ''})
    const [loading, setLoading] = useState(false)
    const [loadingName, setLoadingName] = useState(false)
    const onSubmit = async () => {
        setErrors({})
        if (qty > product.max_qty || qty < product.min_qty) {
            setErrors({...errors, qty: `يرجى تحديد الكمية بين ${product.min_qty} -> ${product.max_qty}`})
            return;
        }
        if (player_id.length === 0) {
            setErrors({...errors, player_id: `يرجى إدخال رقم اللاعب`})
            return;
        }
        setLoading(true)
        try {
            const data = new FormData()
            data.append('player_id', player_id)
            data.append('player_name', player_name)
            data.append('product_id', product.id)
            data.append('qty', qty)
            data.append('note', note)
            const response = await baseApi.post('/orders', data)
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.setItem('user', JSON.stringify(response.data?.user))

            }
            redirect('/orders')
        } catch (e) {
            setErrors({...errors, msg: e.response.data?.msg})
        }
        setLoading(false)

    }
    const checkPlayer = async () => {
        setLoadingName(true)
        const name = await authStore.getPlayerName(product.id, player_id);
        setPlayerName(name)
        setLoadingName(false)
    }

    useEffect(() => {
        setTotalPrice(product.unit_price * qty);

        if (qty > product.max_qty || qty < product.min_qty) {
            setErrors({...errors, qty: `يرجى تحديد الكمية بين ${product.min_qty} -> ${product.max_qty}`})
        } else {
            setErrors({...errors, qty: ''})
        }

    }, [qty])
    return (
        <div className="col-md-8 ">
            <div className="form mx-3 my-2">
                <p className={'text-dark'}><i className="fas fa-caret-left text-dark fa-2x"/>تأكيد الشراء  -  {product.name}</p>

                <ul>
                    {Object.entries(errors).map(([key, value], i) => {
                        if (value) {
                            return <li key={i} className={'text-orange'}>
                                {value}
                            </li>
                        }
                    })}
                </ul>
                <div className=" px-2">
                    <div className="form-group">
                        <label htmlFor="">{product.type === 'id' ? 'ID الحساب' : product.type === 'account' ? 'اسم المستخدم /  البريد الإلكتروني' : 'رقم الهاتف/رابط الصفحة'}</label>
                        <input type="text" onChange={(e) => setPlayerId(e.target.value)}
                               placeholder={product.type === 'id' ? 'ID الحساب' : product.type === 'account' ? 'اسم المستخدم /  البريد الإلكتروني' : 'رقم الهاتف/رابط الصفحة'}
                               className={'input-form-buy my-2'}/>
                    </div>
                    {authStore.checkPlayer && product.is_check ?
                        <div
                            className={'d-flex justify-content-evenly align-items-center gap-4 bg-green  input-form-buy cursor-pointer'}
                            onClick={checkPlayer}>

                            {player_name?.trim().length == 0 ?
                                <span className={'d-block text-white small'}>أضغط على السهم لفحص الاسم</span> :
                                <span className={'text-white small d-block'}>{player_name}</span>}
                            {authStore.checkPlayer && product.is_check ?
                                <span className={`checkName ${loadingName ? 'loading' : ''}`} onClick={checkPlayer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>
                        </span>
                                : null}
                        </div>
                        : <div className={'form-group'}>
                            <label htmlFor="">{product.type === 'id' ? 'اسم الحساب' : product.type === 'account' ? 'كلمة المرور' : 'اسم الحساب'}</label>
                            <input value={player_name} type="text" onChange={(e) => setPlayerName(e.target.value)}
                                   placeholder={product.type === 'id' ? 'اسم الحساب' : product.type === 'account' ? 'كلمة المرور' : 'اسم الحساب'}
                                   className={'input-form-buy my-2'}/>
                        </div>
                    }


                    <div className="d-flex justify-content-between gap-2 w-250">

                            <div className="form-group w-50">
                                <label className={'d-block text-dark'} htmlFor="">الكمية</label>
                                <input onChange={(e) => setQty(e.target.value)}
                                       type="text"
                                       onKeyPress={(event) => {
                                           const charCode = event.charCode;
                                           if (charCode < 48 || charCode > 57) {
                                               event.preventDefault();
                                           }
                                       }} value={qty}
                                       pattern="[0-9]*"
                                       placeholder="الكمية"
                                       className=" input-form-buy my-2 "
                                />
                            </div>


                            <div className="form-group w-50">
                                <label className={'d-block text-dark'} htmlFor="">السعر</label>
                                <input type="text" className="input-form-buy my-2 bg-green fs-6 text-white " readOnly={'readOnly'}
                                       value={`${authStore.formatCurrency(totalPrice)} ${authStore.icon === 'syr' ? 'ل.س' : ''} `}/>
                            </div>




                    {/* <span className={'text-orange d-inline-block mx-1 small'}>{totalPrice.toFixed(2)} $</span>*/}
                </div>
                <div className="form-group">
                    <label className={'text-dark'} htmlFor="">ملاحظات</label>
                    <textarea  rows={5} cols={6} onChange={(e) => setNote(e.target.value)} multiple={4} placeholder={'ملاحظات'}
                               className={'input-form-area my-2'}/>
                </div>


               <div className="">
                   {!loading ? (
                       authStore.user === null ? (
                           <Link to="/login" className="btn d-block btn-sm submit-btn">تسجيل الدخول</Link>
                       ) : (
                           <button onClick={onSubmit} className="btn d-block btn-sm submit-btn w-50 m-auto fs-4">شراء</button>
                       )
                   ) : (
                       <button className="btn d-block btn-sm submit-btn">جاري الطلب</button>
                   )}
               </div>


            </div>
        </div>

</div>
)
}