import {Link,useNavigate} from "react-router-dom";

import logo from '../../assets/images/bupg.jpg'
import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";


export default function LoginComponent() {
const redirect=useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const authStore = useAuthStore()
    const login = async () => {
        setLoading(true)
        const data = new FormData()
        data.append('email', email)
        data.append('password', password)
        try {
            const response = await baseApi.post('/login', data)
            localStorage.setItem('user',JSON.stringify(response.data?.user))
            localStorage.setItem('token',response.data?.token)
            baseApi.defaults.headers.common.Authorization = `Bearer ${response.data?.token}`;
            authStore.setUser(response.data?.user)
            redirect('/')
            // authStore.setUser(,)

        } catch (e) {
            setError(e.response.data?.msg)
        }
        setLoading(false)

    }
    return (
        <div className={'container'}>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-8 ">
                    <h2 className={'text-white fs-6'}>أهلا بك!</h2>
                </div>
                <div className="col-md-8">
                    <h2 className={'text-green fs-5'}>تسجيل الدخول</h2>
                </div>
                <div className="col-md-8">

                    <div className="d-flex flex-column justify-content-center align-items-center card-auth bg-white"
                         style={{flex: '1 0 auto'}}>
                        <img className={'logo-auth'}  src={authStore.settings?.logo} alt=""/>
                        {error.length > 0 ? <span className="text-danger">{error}</span> : null}
                        <input type="email" onChange={(e) => setEmail(e.target.value)} value={email}
                               className="input-form-green  my-2 border-green border border-2 bg-white placeholder text-green" placeholder={'البريد الإلكتروني'} autoComplete={'off'}/>
                        <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                               className="input-form-green  my-2 border-green border border-2 bg-white placeholder text-green" placeholder={'كلمة المرور'} autoComplete={'off'}/>
                        {!loading?<button onClick={login} className="btn d-block btn-sm submit-btn-green ">دخول</button>:<button  className="btn d-block btn-sm submit-btn ">جاري المعالجة</button>}
                        <Link to={'/register'} className="btn d-block btn-sm dark-btn-green border-green border my-2">ليس لدي حساب</Link>
                    </div>
                </div>

            </div>
        </div>
    )
}