import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import LoadingComponent from "../Loading/LoadingComponent";
import OrderItem from "../OrderSections/OrderItem";
import {useAuthStore} from "../../store/user-store";

export default function BalanceSection() {
    const [loading, setLoading] = useState(false)
    const [balances, setBalances] = useState([])
    const [error, seterror] = useState([])
    const authStore = useAuthStore()
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [prevPage, setPrevPage] = useState(null)
    const nextPageNum = () => {
        setPage(page + 1)
    }
    const prevPageNum = () => {
        if (page > 1) {
            setPage(page - 1)
        }

    }
    const getBalances = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/balances?page=${page}`)
            setBalances(response.data?.balances)
            setNextPage(response.data?.next)
            setPrevPage(response.data?.back)
        } catch (e) {
            console.log('ERROR', e.response)
        }
        setLoading(false)
    }
    useEffect(() => {
        getBalances()
    }, [page])
    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-6 text-center">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    } else if (!loading && balances.length == 0) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-6">
                    <h6 className="alert alert-danger">لا يوجد بيانات لعرضها</h6>
                </div>
            </div>
        </div>
    } else {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    {balances.length > 0 ? balances.map((el) => {
                        if (el.credit > 0) {
                            return (
                                <div className={'row justify-content-center my-2 '}>
                                    <div className="col-9 col-sm-7">
                                        <div className="card bg-green-50 text-white pull border-0 fs-8">
                                            <div
                                                className="card-header">القيمة : {authStore.formatCurrency(el?.credit)} {authStore.icon==='syr'?'ل.س':'$'}</div>
                                            {el.info?.length>2?<div className="card-body">{el.info}</div>:null}
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-between">
                                                    <span className={''}>{el.created_at}</span>
                                                    <span className={'fs-8'}>الرصيد : {el.total}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }else{
                            return (
                                <div className={'row justify-content-center my-2 '}>
                                    <div className="col-9 col-sm-7">
                                        <div className="card bg-red-50 text-white push border-0 fs-8">
                                            <div
                                                className="card-header">القيمة : {authStore.formatCurrency(el?.debit)} {authStore.icon==='syr'?'ل.س':'$'}</div>
                                            {el.info?.length>2?<div className="card-body">{el.info}</div>:null}
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-between">
                                                    <span>{el.created_at}</span>
                                                    <span>الرصيد : {el.total}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }):null

                    }

                </div>
            </div>
        </div>
    }
}





