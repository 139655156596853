import OrderSectionComponent from "../components/OrderSections/OrderSectionComponent";

export default function  OrderPage(){

    return (
       <>
           <OrderSectionComponent/>
       </>

    )
}