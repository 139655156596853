import {useAuthStore} from "../../store/user-store";

export default function MarqueeComponent ({text='مرحبا بكم'}){
    const authStore = useAuthStore()
    return (
        <div className={'container mt-2'}>
            <div className="row">
                <div className="col-12">
                    <div className={'marquee marquee-container bg-green-50 rounded-ellipse'}>
                        <div className={'marquee-content fs-6'}>{authStore.settings?.post?.length>0?authStore.settings?.post:text}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}