import DepartmentComponent from "../components/Online/DepartmentComponent";
import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";

export default function OnlinePage(){
    return (
        <>
            <SliderComponent/>
            <MarqueeComponent/>
        <DepartmentComponent/>
        </>
    )
}