import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import DepartmentSectionComponent from "../components/DepartmentSection/DepartmentSectionComponent";

export default function DepartmentPage(){
    return(
        <>
        <SliderComponent/>
            <MarqueeComponent/>
            <DepartmentSectionComponent/>
        </>
    )
}