import {Link,useNavigate} from "react-router-dom";


import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";


export default function RegisterComponent() {
const redirect=useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])
    const authStore = useAuthStore()
    const login = async () => {
    setErrors([])
        setLoading(true)
        const data = new FormData()
        data.append('email', email)
        data.append('password', password)
        data.append('name', name)
        data.append('phone', phone)
        try {
            const response = await baseApi.post('/register', data)
            localStorage.setItem('user',JSON.stringify(response.data?.user))
            localStorage.setItem('token',response.data?.token)
            baseApi.defaults.headers.common.Authorization = `Bearer ${response.data?.token}`;
            authStore.setUser(response.data?.user)
            redirect('/')
            // authStore.setUser(,)

        } catch (e) {

            const valuesArray = Object.values(e.response.data?.errors).flatMap(value => value);

            setErrors(valuesArray)
        }
        setLoading(false)

    }
    return (
        <div className={'container'}>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-8">
                    <h2 className={'text-white fs-6'}>أهلا بك!</h2>
                </div>
                <div className="col-md-8">
                    <h2 className={'text-green fs-5'}>تسجيل حساب جديد</h2>
                </div>
                <div className="col-md-8">

                    <div className="d-flex flex-column justify-content-center align-items-center card-auth bg-white"
                         style={{flex: '1 0 auto'}}>
                        <img className={'logo-auth'} src={authStore.settings?.logo} alt=""/>
                        <ul>
                            {errors?.map((el)=><li key={Math.random()} className="text-danger">{el}</li> )}
                        </ul>
                        <input type="text" onChange={(e) => setName(e.target.value)}
                               className="input-form-green  my-2 border-green border border-2 bg-white placeholder text-green" placeholder={'الاسم'} autoComplete={'off'}/>
                        <input type="email" onChange={(e) => setEmail(e.target.value)}
                               className="input-form-green  my-2 border-green border border-2 bg-white placeholder text-green" placeholder={'البريد الإلكتروني'} autoComplete={'new-email'}/>
                        <input type="text" onChange={(e) => setPhone(e.target.value)}
                               className="input-form-green  my-2 border-green border border-2 bg-white placeholder text-green" placeholder={'رقم الهاتف'} autoComplete={'off'}/>
                        <input type="password" onChange={(e) => setPassword(e.target.value)}
                               className="input-form-green  my-2 border-green border border-2 bg-white placeholder text-green" placeholder={'كلمة المرور'} autoComplete={'new-password'}/>
                        {!loading?<button onClick={login} className="btn d-block btn-sm submit-btn-green ">تسجيل جديد</button>:
                            <button  className="btn d-block btn-sm submit-btn-green ">جاري المعالجة</button>}
                        <Link to={'/login'} className="btn d-block btn-sm dark-btn-green border-green border my-2"> لدي حساب بالفعل</Link>
                    </div>
                </div>

            </div>
        </div>
    )
}