import {Link, NavLink, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

import {useEffect} from "react";


export default function Layout({children}) {
    const auth_store = useAuthStore()
    const redirect = useNavigate()
    const logOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        auth_store.logOut()
        redirect('/')
    }
    useEffect(() => {
        console.log(window.location.pathname)
        document.title = auth_store.settings?.name || 'الصفحة الرئيسية'
        if (auth_store.settings?.logo) {
            const link = document.querySelector("link[rel~='icon']");
            if (link) {
                link.href = auth_store.settings.logo;
            }
        }
    }, [auth_store.settings])

    return (
        <>

            <section  className={`${auth_store.gradient?'bg-gradient-main':'bg-color'} section-wrapper`}>


                   <nav className={`navbar ${auth_store.gradient?'bg-gradient-main':'nav-color'} w-99 bg-white rounded-ellipse border border-green border-2 nav-h fixed-top box-shadow mb-4 mt-1 mx-auto`} dir={'ltr'}>
                       <div className="container-fluid">
                        <div className="d-flex justify-content-evenly w-100">
                            <div className="d-flex flex-1 justify-content-between align-items-center text-center">

                                {auth_store.user ? <Link to={'/balances'} className="navbar-brand text-dark"><span dir={'rtl'}
                                                                                                                   className="text-dark d-inline-block mx-5" style={{fontSize:'14px'}}> {auth_store.formatCurrency(auth_store.user?.balance)}  {auth_store.icon==='syr'?'ل.س':''}</span>
                                </Link> : null}
                                <Link to={'/'} className="navbar-brand text-dark d-none d-md-inline-block flex-1">{auth_store.settings?.name}  </Link>
                            </div>
                            <button className="border-0 max-width-25 outline-0 rounded px-2 py-1 bg-green" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i
                                className={'fa fa-bars fa-lg text-white'}/>
                            </button>
                        </div>
                       </div>

                   </nav>


                {/* Start SideBar */}
                <div className="offcanvas offcanvas-end bg-gradient-main sidebar-width" tabIndex="-1" id="offcanvasRight"
                     aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header bg-gradient-main">
                        <div className="d-flex justify-content-end align-items-center flex-1 ">
                           {/* <h5 className="offcanvas-title text-white flex-1"
                                id="offcanvasRightLabel"> {auth_store.user ? <span>
                            <img className={'group-img'} src={auth_store.user?.group_img} alt=""/>
                            <span>{auth_store.user?.name}</span> <span dir={'ltr'}
                                                                       className="text-orange">

                                    <div className={'d-flex flex-row gap-1'} dangerouslySetInnerHTML={{ __html: auth_store.formatCurrency(auth_store.user?.balance) }} />
                                </span></span> :
                                <span>{auth_store.settings?.name}</span>}</h5>*/}
                            <button type="button" className="bg-transparent border-0 text-white"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close">
                                <i className="fa fa-close"></i>
                            </button>
                        </div>
                    </div>
                    <div className="offcanvas-body bg-gradient-main">
                        <ul className={'nav flex-column'} data-bs-dismiss="offcanvas"
                            aria-label="Close">
                            {auth_store.user !=null ?  <li className={'nav-item mb-3   rounded my-1'}>
                                <img className={'group-img-small'} src={auth_store.user?.group_img} alt=""/>
                                <span className={'text-dark'}>{auth_store.user.name}</span>
                            </li>:null}

                           {/* <li className={'nav-item border  border-1 border-white rounded my-1'}>

                                <Link className={'nav-link  text-white'} to={'/balances'}><span
                                                                                   className="text-green d-inline-block text-right " style={{fontSize:'14px'}}> {auth_store.formatCurrency(auth_store.user?.balance)}  {auth_store.icon==='syr'?'ل.س':''}</span></Link>
                            </li>*/}
                            <li className={'nav-item border  border-3 border-white rounded my-1'}>

                                <Link className={'nav-link  text-dark'} to={'/'}> <i
                                    className="fa fa-home text-green mx-2"/>الرئيسية</Link>
                            </li>
                            {auth_store.user !== null ?  <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/profile'}><i
                                    className="fa fa-user text-green mx-2"/>الملف الشخصي</Link>
                            </li> : null}
                            {auth_store.user === null ?  <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/login'}><i
                                    className="fa fa-right-to-bracket text-green mx-2"/>تسجيل الدخول</Link>
                            </li> : null}
                            {auth_store.user === null ?  <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/register'}><i
                                    className="fa fa-id-card text-green mx-2"/>تسجيل جديد</Link>
                            </li> : null}


                            {auth_store.user !== null ? <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/payments'}><i
                                    className="fa fa-circle-dollar-to-slot text-green mx-2"/>شحن الرصيد</Link>
                            </li> : null}

                            {auth_store.user !== null ? <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/charges'}><i
                                    className="fa fa-layer-group text-green mx-2"/>طلبات شحن الرصيد</Link>
                            </li> : null}



                            {auth_store.user !== null ?  <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/orders'}><i
                                    className="fa fa-basket-shopping text-green mx-2"/>المشتريات</Link>
                            </li> : null}
                            {auth_store.user !== null && auth_store.online===true ? <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/invoices'}><i
                                    className="fa fa-basket-shopping text-green mx-2"/>مشتريات الأرقام</Link>
                            </li> : null}


                            {auth_store.user !== null ?  <li className={'nav-item border  border-3 border-white rounded my-1'}>
                                <Link className={'nav-link  text-dark'} to={'/balances'}><i
                                    className="fa fa-dollar-sign text-green mx-2"/>حركة الرصيد</Link>
                            </li> : null}
                           {/* <li className={'nav-item border  border-1 border-white rounded my-1'}>
                                <a className={'nav-link  text-white '} style={{fontSize:'14px'}} href={`https://wa.me/${auth_store.settings?.whatsapp}`} target={'_blank'}><i
                                    className="fa-brands fa-whatsapp whatsapp-icon fa-xl mx-2 small" />لشحن الرصيد تواصل مع الإدارة </a>
                            </li>*/}

                            {auth_store.user != null ?
                                <li className={'nav-item border  border-1 border-white rounded-ellipse my-1 w-75 mx-auto bg-green text-center'} onClick={logOut}>

                                <span className={' nav-link text-white'}>خروج</span>
                            </li> : null}

                            <li className={'nav-item my-2 border-3 border-top pt-2 gap-2 d-flex'}>
                                {/*<a className={'nav-link  text-white'} target={'_blank'} rel="noopener noreferrer" href={`https://wa.me/${auth_store.settings?.whatsapp}`}>
                                    <i className="fa-brands fa-whatsapp  text-orange fa-lg mx-2" />تواصل مع الإدارة</a>*/}
                                <a href={`https://wa.me/${auth_store.settings?.whatsapp}`} target={'_blank'}>
                                    <i className="fa-brands fa-whatsapp whatsapp-icon fa-2xl"/>
                                </a>
                                <a href={auth_store.settings?.facebook} target={'_blank'}>
                                    <i className="fa-brands fa-facebook fa-2xl facebook-icon"/>
                                </a>
                                <a href={auth_store.settings?.telegram} target={'_blank'}>
                                    <i className="fa-brands fa-telegram fa-2xl telegram-icon"/>
                                </a>
                                <a href={auth_store.settings?.instagram} target={'_blank'}>
                                    <i className="fa-brands fa-instagram fa-2xl instagram-icon"/>
                                </a>


                            </li>
                        </ul>
                    </div>
                </div>
                {/* End SideBar */}
                <div className={'wrapper min-100-vh'}>
                    {children}
                </div>

            </section>
            <footer className={'bottom-nav py-1 d-block d-md-none'}>
                <div className="d-flex justify-content-around align-items-center">

                    <NavLink to={'/balances'}>
                        {auth_store.icon==='syr'?<i className={'fa fa-lira-sign fa-lg text-white'}/>:null}
                        {auth_store.icon==='coins'?<i className="fa-solid fa-coins fa-lg text-white"/>:null}
                        {auth_store.icon==='usd'?<i className="fa fa-dollar-sign fa-lg text-white"/>:null}
                    </NavLink>
                    <NavLink to={'/'}><i className="fa fa-home fa-lg text-white"></i></NavLink>
                    <NavLink to={'/orders'}><i className="fa fa-shopping-basket fa-lg text-white"></i></NavLink>
                </div>
            </footer>

        </>

    );
}
