import {useEffect, useState} from "react";
import LoadingComponent from "../Loading/LoadingComponent";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import CategoryItem from "./CategoryItem";
import {Link} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function CategorySectionComponent() {
    const authStore = useAuthStore()
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/categories')
            setCategories(response.data?.categories)
        } catch (e) {
            toast.dark(e.response.data?.msg)
        }
        setLoading(false)
        // const response=await
    }

    useEffect(() => {
        getCategories()
    }, [])
    if (loading) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-12 text-center'}>
                        <LoadingComponent/>
                    </div>
                </div>

            </div>
        )
    } else if (!loading && categories.length === 0) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-6 text-center'}>
                        <p className={'text-white bg-primary rounded py-2 fs-5 box-shadow'}>
                            القسم غير متوفر حاليا
                        </p>
                    </div>
                </div>

            </div>
        )
    } else {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center align-items-center">
                    {authStore.online === true ? <div className={'col-md-3 col-6 my-1'}>
                        <Link to={`/online`}>
                            <div className=" category-item ">
                                <div className="background"
                                     style={{backgroundImage: `url(${authStore.settings?.img_online})`}}></div>
                                <img className={'m-auto d-block'} src={authStore.settings?.img_online} loading={'lazy'}
                                     alt=""/>
                                <div className={'category-item-overlay text-white text-center py-2'}>تفعيل الأرقام</div>
                            </div>
                        </Link>
                    </div> : null}
                    {categories.map((item) => {
                        return <CategoryItem key={item.id} item={item}/>
                    })}
                </div>

            </div>
        )
    }
}