import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import LoadingComponent from "../Loading/LoadingComponent";
import {Link} from "react-router-dom";
import CategoryItem from "../CategorySection/CategoryItem";

export default function DepartmentComponent() {
    const [loading, setLoading] = useState(false)
    const [departments, setDepartments] = useState([])

    const getDepartments = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/online')
            if (response.data.departments?.length > 0) {
                setDepartments(response.data.departments)
            }
        } catch (e) {

        }
        setLoading(false)
    }

    useEffect(()=>{
        getDepartments()
    },[])
    if(loading){
       return (
           <div className={'container'}>
               <div className="row justify-content-center">
                   <div className="col-md-6">
                       <LoadingComponent/>
                   </div>
               </div>
           </div>
       )
    }
if(!loading && departments?.length===0){
    return (
        <div className={'container'}>
            <div className="row justify-content-center">
                <div className={'col-6 text-center'}>
                    <p className={'text-white bg-primary rounded py-2 fs-5 box-shadow'}>
                        لا يوجد أقسام متوفرة
                    </p>
                </div>
            </div>
        </div>
    )
}
    if(!loading && departments?.length>0){
        return (
            <div className={'container'}>
                <div className="row justify-content-center">
                    {departments?.map((item)=>{
                       return  <div className={'col-md-3 col-6 my-1'}>
                            <Link to={`/online/${item.id}`}>
                                <div className=" category-item " >
                                    <div className="background" style={{backgroundImage: `url(${item.img})`}}></div>
                                    <img className={'m-auto d-block'} src={item.img} loading={'lazy'} alt=""/>
                                    <div className={'category-item-overlay text-white text-center py-2'}>{item.name}</div>
                                </div>
                            </Link>
                        </div>
                    })}
                </div>
            </div>
        )
    }

}