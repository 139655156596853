import {Link} from "react-router-dom";

export default function CategoryItem({item}) {

    return (
        <div className={'col-md-2 col-6 my-1'}>
            <Link to={`/categories/${item.id}`} className={'decoration-none'}>
            <div className=" category-item d-flex flex-column align-items-center justify-content-center" >
                {/*<div className="background" style={{backgroundImage: `url(${item.img})`}}></div>*/}
                <img className={'m-auto d-block'} src={item.img} loading={'lazy'} alt=""/>
                <div className={'z-1  text-dark text-center py-2'}>{item.name}</div>
            </div>
            </Link>
        </div>

    )
}