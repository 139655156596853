import LoadingComponent from "../Loading/LoadingComponent";
import DepartmentItem from "../DepartmentSection/DepartmentItem";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import ProductItem from "./ProductItem";

export default function ProductsSection(){
    const department_id=useParams().id
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const getProducts = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/products?category_id=${department_id}`)
            setProducts(response.data?.products)
        } catch (e) {
            toast.dark(e.response.data?.msg)
        }
        setLoading(false)
        // const response=await
    }

    useEffect(() => {
        getProducts()
    }, [])
    if (loading) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-12 text-center'}>
                        <LoadingComponent/>
                    </div>
                </div>

            </div>
        )
    }
    else if (!loading && products.length === 0) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-6 text-center'}>
                        <p className={'text-white bg-primary rounded py-2 fs-5 box-shadow'}>لا يوجد أقسام </p>
                    </div>
                </div>

            </div>
        )
    }
    else {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center align-items-center">

                    {products.map((item)=>{
                        return <ProductItem key={item.id} item={item}/>
                    })}
                </div>

            </div>
        )
    }
}