import {useEffect, useState} from "react";
import LoadingComponent from "../Loading/LoadingComponent";
import {baseApi} from "../../api/axios";
import {toast} from "react-toastify";
import DepartmentItem from "./DepartmentItem";
import {useParams} from "react-router-dom";

export default function DepartmentSectionComponent() {

    const [loading, setLoading] = useState(false)
    const department_id=useParams().id;
    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/categories/${department_id}`)
            setCategories(response.data?.categories)
        } catch (e) {
            toast.dark(e.response.data?.msg)
        }
        setLoading(false)
        // const response=await
    }

    useEffect(() => {
        getCategories()
    }, [])
    if (loading) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-12 text-center'}>
                        <LoadingComponent/>
                    </div>
                </div>

            </div>
        )
    }
    else if (!loading && categories.length === 0) {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center">
                    <div className={'col-6 text-center'}>
                        <p className={'text-white bg-primary rounded py-2 fs-5 box-shadow'}>القسم غير متوفر حاليا </p>
                    </div>
                </div>

            </div>
        )
    }
    else {
        return (
            <div className={'container mt-3'}>
                <div className="row justify-content-center align-items-center">

                          {categories.map((item)=>{
                              return <DepartmentItem key={item.id} item={item}/>
                          })}
                </div>

            </div>
        )
    }
}