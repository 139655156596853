import ProductDetails from "../components/ProductsSection/ProductDetails";
import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";

export default function ProductPage() {
    return (
        <>
            <SliderComponent/>
            <MarqueeComponent/>
            <ProductDetails/>
        </>
    )
}