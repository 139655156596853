import {useState} from "react";
import {baseApi} from "../../api/axios";

import {Link, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

export default function PayFixedProduct({product}) {
    const authStore = useAuthStore()
    const [errors, setErrors] = useState({});
    const [player_id, setPlayerId] = useState('');
    const [player_name, setPlayerName] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState('');
    const redirect = useNavigate()
    const [loadingName, setLoadingName] = useState(false)
    const checkPlayer = async () => {
        setLoadingName(true)
        setPlayerName('')
        const name = await authStore.getPlayerName(product.id, player_id);
       // console.log(name,'NAME IS')
        setPlayerName(name)
        setLoadingName(false)
    }
    const onSubmit = async () => {
        setLoading(true)
        setErrors({})
        if (player_id.length === 0) {
            setErrors({...errors, msg: 'يرجى إدخال رقم اللاعب'})
            return;
        }
        const data = new FormData();
        data.append('player_id', player_id)
        data.append('player_name', player_name)
        data.append('product_id', product.id)
        data.append('note', note)
        try {
            const response = await baseApi.post('/orders', data)
            if(response.data?.user){
                authStore.setUser(response.data?.user)
                localStorage.setItem('user',JSON.stringify(response.data?.user))
            }

            redirect('/orders')
        } catch (e) {
            setErrors({...errors, msg: e.response?.data?.msg})
        }
        setLoading(false)


    }
    return (
        <div className="col-md-9 ">
            <div className="form mx-3">
                <p className={'text-dark'}><i className="fas fa-caret-left text-dark fa-2x"/>تأكيد الشراء  -  {product.name}</p>
                <ul>
                    {/* eslint-disable-next-line array-callback-return */}
                    {Object.entries(errors).map(([key, value], i) => {
                        if (value) {
                            return <li key={i} className={'text-dark'}>
                                {value}
                            </li>
                        }
                    })}
                </ul>
                <div className=" px-2">
                  <div className="form-group">
                      <label htmlFor="">{product.type==='id'?'ID الحساب':product.type==='account'?'اسم المستخدم /  البريد الإلكتروني':'رقم الهاتف/رابط الصفحة'}</label>
                      <input type="text" onChange={(e) => setPlayerId(e.target.value)}  placeholder={product.type==='id'?'ID الحساب':product.type==='account'?'اسم المستخدم /  البريد الإلكتروني':'رقم الهاتف/رابط الصفحة'}
                             className={'input-form-buy my-2'}/>
                  </div>
                    {authStore.checkPlayer && product.is_check?
                        <div className={'d-flex justify-content-evenly align-items-center gap-4 bg-green  input-form-buy cursor-pointer'} onClick={checkPlayer}>

                            {player_name?.trim().length==0 ?<span className={'d-block text-white small'}>أضغط على السهم لفحص الاسم</span>:<span className={'text-white small d-block'}>{player_name}</span>}
                            {authStore.checkPlayer && product.is_check  ?
                                <span className={`checkName ${loadingName?'loading':''}`} onClick={checkPlayer}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg>
                        </span>
                                : null}
                        </div>
                    : <div className={'form-group'}>
                            <label htmlFor="">{product.type==='id'?'اسم الحساب':product.type==='account'?'كلمة المرور':'اسم الحساب'}</label>
                            <input value={player_name} type="text" onChange={(e) => setPlayerName(e.target.value)} placeholder={product.type==='id'?'اسم الحساب':product.type==='account'?'كلمة المرور':'اسم الحساب'}
                                   className={'input-form my-2'}/>
                        </div>
                    }

                    <div className="form-group">
                        <label className={'d-block'} htmlFor="">ملاحظات</label>
                        <textarea onChange={(e) => setNote(e.target.value)} className={'input-form-area my-2'} cols="30"
                                  rows="4" placeholder={'ملاحظات'}></textarea>
                    </div>
                    {!loading ? (
                        authStore.user === null ? (
                            <Link to="/login" className="btn d-block btn-sm submit-btn">تسجيل الدخول</Link>
                        ) : (
                            <button onClick={onSubmit} className="btn d-block btn-sm submit-btn w-50 fs-4 m-auto">شراء</button>
                        )
                    ) : (
                        <button className="btn d-block btn-sm submit-btn">جاري الطلب</button>
                    )}


                </div>
            </div>

        </div>
    )
}