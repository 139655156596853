import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {useAuthStore} from "../../store/user-store";

export default function OrderItem({order}) {

    const [item, setItem] = useState(order)
    const [loading, setLoading] = useState(false)
    const authStore = useAuthStore()

    useEffect(() => {
        let timer = setInterval(async () => {
            if (!loading) {
                getStatus();
            }
        }, 15000);

        return () => {
            clearInterval(timer);
        };
    }, [loading]);

    const getStatus = async () => {
        setLoading(true);
        try {
            if (item.is_pending === true) {
                const response = await baseApi.get(`/orders/${order.id}`);
                if (response.data?.order) {
                    if (response.data?.order.is_pending === false) {

                        setItem(response.data?.order);
                        if (response.data?.user) {
                            authStore.setUser(response.data?.user)
                            localStorage.setItem('user', JSON.stringify(response.data?.user))
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    return (
      /*  <tr>
            <td>{order.id}</td>
            <td>{order.product}</td>
            <td className={'text-blue'}>{order.price.toFixed(authStore.currency==='syr'?0:2)} {authStore.currency==='syr'?'ل.س':'$'} </td>
            <td>
                {order.send_data?.player_id ?
                    <span className={'text-orange'}> ID : {order.send_data?.player_id}</span> : null}
                {order.send_data?.email ?
                    <span className={'text-orange'}> Email : {order.send_data?.email}</span> : null}
                {order.send_data?.password ?
                    <span className={'text-orange'}> Password : {order.send_data?.password}</span> : null}
                {order.send_data?.qty ? <span className={'text-orange'}> الكمية : {order.send_data?.qty}</span> : null}
            </td>
            <td>{item?.receive_data?.msg}</td>
            <td>{item?.status}</td>
            <td>{order.created_at}</td>
        </tr>*/
        <div className="accordion my-2" id={`accordionExample${item?.id}`}>
            <div className="accordion-item">
                <h2 className="accordion-header bg-white " dir={`ltr`}>
                    <button className="accordion-button bg-white" type="button" data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${item?.id}`} aria-expanded="true" aria-controls={`collapseOne${item?.id}`}>
                        <div className={'d-flex justify-content-evenly gap-2 w-70'}>
                            <span className={'fs-8'}>ID:#{order.id}</span>

                            {item.status_en==='pending'?
                             <span className={'fs-8'} dir={`ltr`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0000FF"
                                         className="bi bi-alarm" viewBox="0 0 16 16">
                                    <path
                                        d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z"/>
                                    <path
                                        d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1"/>
                                </svg>
                                 <span className={`d-inline-block mx-2`}>بالإنتظار</span>
                             </span>
                             :null}
                            {item.status_en==='success'?
                                <span className={'fs-8'} dir={`ltr`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00FF00"
                                     className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
                                 <span className={`d-inline-block mx-2`}>مقبول</span>
                             </span>
                                :null}
                            {item.status_en==='cancel'?
                                <span className={'fs-8'} dir={`ltr`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF0000"
                                     className="bi bi-ban" viewBox="0 0 16 16">
                                    <path
                                        d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0"/>
                                </svg>
                                 <span className={`d-inline-block mx-2`}>مرفوض</span>
                             </span>
                                :null}
                            <span className={'fs-8'}> المنتج: {order.product} </span>
                        </div>

                    </button>
                </h2>
                <div dir={`rtl  `} id={`collapseOne${item?.id}`} className="accordion-collapse collapse show" data-bs-parent={`accordionExample${item?.id}`}>
                    <div className="accordion-body">
                        <div className="d-flex flex-column">

                                <span className={'border p-2 border-1 border-success d-block'}>السعر :
                                <span className={'text-green'}>{order.price.toFixed(authStore.icon==='syr'?0:2)} {authStore.icon==='syr'?'ل.س':'$'}</span>
                                </span>
                            {order.send_data?.player_id?.length >0||
                                order.send_data?.email?.length >0||
                                order.send_data?.password?.length >0||
                                order.send_data?.qty?.length >0?  <span className={'border p-2 border-1 border-success d-block my-1'}>بيانات المشتري :
                                {order.send_data?.player_id ?
                                    <span className={'text-green'}> ID : {order.send_data?.player_id}</span> : null}
                                {order.send_data?.email ?
                                    <span className={'text-green'}> Email : {order.send_data?.email}</span> : null}
                                {order.send_data?.password ?
                                    <span className={'text-green'}> Password : {order.send_data?.password}</span> : null}
                                {order.send_data?.qty ? <span className={'text-green'}> الكمية : {order.send_data?.qty}</span> : null}
                            </span>:null}
                            {item?.receive_data?.msg?.trim().length>0? <span  className={'border p-2 border-1 border-success d-block my-1'}>بيانات البائع :
                                <span className={'text-green'}>{item?.receive_data?.msg}</span>
                                </span>:null}

<span className={'border p-2 border-1 border-success d-block my-1'}>تاريخ الشراء :
<span>{order.created_at}</span>
</span>




                        </div>
                    </div>
                </div>
            </div>
        </div>
            )
}