
import SliderComponent from "../components/Slider/SliderComponent";
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import ProductsSection from "../components/ProductsSection/ProductsSection";


export default function ProductsPage(){

    return (
        <>
        <SliderComponent/>
            <MarqueeComponent/>
            <ProductsSection/>

        </>
    )
}